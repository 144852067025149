<template>
  <div id="particulars-schedule" >
    <contact  :dialogVisible5="dialogVisible5"  @dialogVisible5="dialogVisible5=false" ></contact>
		<editmail  :dialogVisible3="dialogVisible3" v-if="dialogVisible3"  @dialogVisible3="dialogVisible3Close"></editmail>
    <div class="show-box" v-if="isLogisticsInfo">

      <div class="logistics">
          <div class="title">
              <span>物流信息</span>
              <span style="cursor:pointer" @click="close"></span>
          </div>
          <div class="content">
              <div class="item">
                  <p>寄件方：</p>
                  <p class="address">{{address}} </p>
              </div>
              <div class="item" style="margin-top: 12px">
                  <p>订单号</p>
                  <div>{{SFNo}}</div>
                  <img :src="imgUrl" class="icon-img"  @click="copyAddress" />
              </div>
                         
              <div class="btn-box" v-if="orderState.indexOf('已取消')==-1&&!isSend">
                  <div @click="showEdit()">
                      <img :src="require('@/assets/logistics_edit.png')">
                      修改订单
                  </div>
                 <!--  <div @click="dialogVisible5=true">
                      <img :src="require('@/assets/logistics_phone.png')">
                      联系快递员
                  </div> -->
                  <div @click="qxjj">
                      <img :src="require('@/assets/logistics_cancel.png')">

                      取消寄件
                  </div>
              </div>
              <div class="list">
                  <div v-for="(item, i) in logisticsInfoList" :key="i">
                      <div>{{ item.date }}</div>
                      <div :class="{ 'last-box': i == logisticsInfoList.length - 1 }">
                          <div class="line" v-if="i!=logisticsInfoList.length-1" :class="{ first: i == 0, last: i == logisticsInfoList.length - 1 }"></div>
                          {{ item.next }}
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    <el-timeline :reverse="reverse">
      <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.startTime + ''">
        <el-image slot="dot" :src="index == 0 ? status[1] : status[0]"></el-image>
        {{ activity.processName }}
        <el-row class="butonezu" v-if="activity.type == 9">
          <el-button class="huyi2" @click="logisticsData(activity.phone, activity.takeNo, activity)">查看</el-button>
        </el-row>
      <el-row class="butonezu" v-if="activity.status == 67">
          <el-button class="huyi2" @click="logisticsInfo(activity)">查看</el-button>
      </el-row>
        <!-- <el-row :class="{buttwozu:true,clearfix:true,planFlag:activity.planFlag}" v-if="activity.type == 6 && activity.processName!='方案需修改'"> -->
        <el-row
          :class="{ buttwozu: true, clearfix: true, planFlag: !activity.planFlag }"
          v-if="activity.type == 6 && activity.processName != '方案需修改'"
        >
          <el-button @click="skip" :disabled="!activity.planFlag"> Web OrthoPlus </el-button>
          <!-- <el-button>
							OrthoPlus
					</el-button> -->
        </el-row>
        <div class="line"></div>
      </el-timeline-item>
    </el-timeline>
    <el-dialog :visible.sync="centerDialogVisible" :close-on-press-escape="false" :close-on-click-modal="false" >
      <p class="trackingNumber">
        物流单号：<span>{{ tokeno }}</span>
      </p>
      <div style="display: flex; margin-bottom: 10px">
        <p style="width:100px">物流信息：</p>
        <div>
          <p class="p1" style="text-align: left">
            <span>{{ address }}</span>
          </p>
          <p class="fl">
            {{ receiver }}<span style="margin-left: 10px">{{ phone }}</span>
          </p>
        </div>
      </div>
      <p style="text-align: left">本次发货</p>
      <div class="koko" style="display: flex; margin-bottom: 5px" v-if="jzqupcount || jzqlowcount">
        <p style="margin-left: 16px">矫治器：</p>

        <div>
          <p>
            上颌：<span>{{ curUpper }}</span> 数量：<span>{{ jzqupcount }}</span>
          </p>
          <p>
            下颌：<span>{{ curLower }}</span> 数量：<span>{{ jzqlowcount }}</span>
          </p>
        </div>
      </div>
      <div class="koko" style="display: flex; margin-bottom: 5px" v-if="bcqlowcount || bcqupcount">
        <p style="margin-left: 16px">保持器：</p>

        <div>
          <p>
            上颌：<span>{{ bcqupstep }}</span> 数量：<span>{{ bcqupcount }}</span>
          </p>
          <p>
            下颌：<span>{{ bcqlowstep }}</span> 数量：<span>{{ bcqlowcount }}</span>
          </p>
        </div>
      </div>
      <div class="koko" style="display: flex; margin-bottom: 5px" v-if="fjmblowcount || fjmbupcount">
        <p>附件模板：</p>

        <div>
          <p>
            上颌：<span>{{ fjmbupstep }}</span> 数量：<span>{{ fjmbupcount }}</span>
          </p>
          <p>
            下颌：<span>{{ fjmblowstep }}</span> 数量：<span>{{ fjmblowcount }}</span>
          </p>
        </div>
      </div>
      <div class="koko" style="display: flex; margin-bottom: 10px" v-show="isStatus!=36"  v-if="palnNo || allUpper || allLower">
        生产方案：
        <div>
          <p style="text-align: left">
            方案<span>{{ planNo }}</span>
          </p>
          <p>
            上颌：<span>1 - {{ allUpper }}</span>
          </p>
          <p>
            下颌：<span>1 - {{ allLower }}</span>
          </p>
        </div>
      </div>
      <div class="wrap">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities2"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
          >
            <p :class="index === 0 ? 'date' : 'specificTime'">{{ activity.desc }}</p>
            <p :class="index === 0 ? 'date' : 'specificTime'">{{ activity.next }}</p>
            <div class="time">
              <p :class="index === 0 ? 'date' : 'specificTime'">{{ activity.date }}</p>
              <p :class="index === 0 ? 'date' : 'specificTime'">{{ activity.time }}</p>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dateToStr } from '@/util/validate.js';
import editmail from "../components/editMail.vue"
	import contact from "../components/contact.vue"
export default {
  components: {
			editmail,
			contact
  },
  data() {
    return {
      isSend:false,
      orderState:'',
      ht: { id: 3, checked: false },
        mt: { id: 2, checked: false },
        jt: { id: 1, checked: false },
      type:'已取消',
      dialogVisible5:false,
      dialogVisible3:false,
      caseNoId:'',
      imgUrl: require("@/assets/copy.png"),
      isLogisticsInfo:false,
      logisticsInfoList: [],
      palnNo: '',
      jzqlowcount: '',
      jzqupcount: '',
      address: '',
      curLower: '',
      curUpper: '',
      allUpper: '',
      allLower: '',
      bcqlowcount: '',
      bcqlowstep: '',
      bcqupcount: '',
      bcqupstep: '',
      fjmblowcount: '',
      fjmblowstep: '',
      fjmbupcount: '',
      fjmbupstep: '',
    isStatus:0,
      planNo: '',
      centerDialogVisible: false,
      reverse: false,
      reverse2: false,
      type: [],
      status: [require('@/assets/2.2病例详情/已完成.png'), require('@/assets/2.2病例详情/进行中.png')],
      reminder: [require('@/assets/2.2病例详情/reminder.png')],
      activities2: [],
      activities: [],
      tokeno: '',
      phone: '',
      receiver: '',
      arrflag: [false, false, false, false]
    };
  },
  mounted() {
    this.$store.commit('changeCopy', true);
    this.getSFById();
    this.init();
  },
  watch:{
    centerDialogVisible(){
      if(this.centerDialogVisible){
        console.log(document.querySelector('body'))
      document.querySelector('body').classList.add('overfollw-body');
      }else{

      document.querySelector('body').classList.remove('overfollw-body');

        }
    }
  },
  methods: {
    copyAddress(){
      let copyInput = document.createElement("input"); //创建input元素
        document.body.appendChild(copyInput); //向页面底部追加输入框
        copyInput.setAttribute(
          "value",
         this.SFNo
           
        ); //添加属性，将url赋值给input元素的value属性
        copyInput.select(); //选择input元素
        document.execCommand("Copy"); //执行复制命令
        this.$message.success("订单号复制成功"); //弹出提示信息，不同组件可能存在写法不同
        //复制之后再删除元素，否则无法成功赋值
        copyInput.remove(); //删除动态创建的节点
    },
    close(){
    this.init()

      this.isLogisticsInfo=false
    },
    qxjj() {
      if (this.isOpen) {
        this.isOpen = false;
        this.$confirm("你是否要取消寄件", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http({
              url: "/webCase/opCancelOrder",
              method: "post",
              data: {
                dealType: 2,
                language: "zh_CN",
                orderId: this.caseNoId,
              },
            })
              .then(({ data }) => {
                this.isOpen = true;

                if (data.code == 200) {
                  this.getSFById();
                  this.$message({
                    message: "取消寄件成功",
                    type: "success",
                    duration: 1500,
                  });
                }
              })
              .catch((err) => {
                this.isOpen = true;

                console.log(err);
              });
          })
          .catch((err) => {
            this.isOpen = true;
            return;
          });
      }
    },
    dialogVisible3Close(){
      this.dialogVisible3=false

        this.isLogisticsInfo=true
    },
  
    showEdit(){
       this.jt.checked=true
     
      this.dialogVisible3=true
      this.isLogisticsInfo=false
    },
    getSFById(isflg) {
  


  let data = { caseIds: this.$store.state.caseId, mailingMethod:1 }; //病例id

  this.$http({
    url: "/webCase/opGetSFById",
    method: "post",
    dataType: "json",
    data: data,
    contentType: "application/x-www-form-urlencoded",
  })
    .then(({ data }) => {
      this.isOpen = true;
     
      if (data.code == 200) {
        if (isflg) {
          this.dialogVisible3 = false;
          this.$message({
            message: "下单成功",
            type: "success",
            duration: 1500,
          });
        }

        let res = JSON.parse(data.data);
        console.log(res);
        if (res != null&&res.orderState!=1) {
  

          this.caseNoId = res.orderNo;
        //  this.type=res.orderState
          // console.log(this.caseNoId)        
       
        }
      }
    })
    .catch((err) => {
      this.isOpen = true;

      console.log(err);
    });
},
    getExpressSend(params) {
            this.$http({
                    url: "/caseInfo/getExpressSend",
                    method: "POST",
                    params

                })
                .then(({
                    data
                }) => {
                    // console.log(data)
                    if (data.code == 200) {

                        let res = JSON.parse(data.data);
                        this.address = res.address
                        this.SFNo = res.takeNo
                        this.isLogisticsInfo = true

                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
    logisticsInfo(val) {
      this.isSend=false

           this.orderState=val.orderState
          
            let params = {
                statusId: val.expressId
            }

            this.$http({
                    url: "/caseInfo/getExpressInfo",
                    method: "POST",
                    params

                })
                .then(({
                    data
                }) => {
                    // console.log(data)
                    if (data.code == 200) {
                        // console.log(JSON.parse(data.data).reverse());
                        this.logisticsInfoList = JSON.parse(data.data);
                        if(this.logisticsInfoList.length>0){
                            this.isSend=true
                        }else{
                          this.isSend=false

                        }
                        this.getExpressSend(params)
                        
                        var that = this;

                        // this.activities2;
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
       
    init() {
      this.$http({
        url: '/caseInfo/caseProcess',
        method: 'POST',
        data: {
          caseId: this.$store.state.caseId
        }
      })
        .then(({ data }) => {
          if (data.code == 200) {
            this.activities = JSON.parse(data.data);
             console.log(this.activities)
            // console.log(this.activities);
            this.activities.forEach((el) => {
              if (el.receiver) {
                this.orderState = el.orderState;
              }

              el.startTime = dateToStr(new Date(el.startTime));
            });
            // this.logisticsData();
            // console.log(this.activities.reverse());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 物流接口
    logisticsData(phone, takeNo, item) {
         console.log(item)
     this.isStatus=item.status
      this.address = item.address;
      this.phone = item.phone;
      this.receiver = item.receiver;

      this.jzqlowcount = item.jzqlowcount;
      this.jzqupcount = item.jzqupcount;
      this.phone = phone;
      this.tokeno = takeNo;
      this.curLower = item.curLower;
      this.curUpper = item.curUpper;
      this.allLower = item.allLower;
      this.allUpper = item.allUpper;
      this.bcqlowcount = item.bcqlowcount;
      this.bcqupcount = item.bcqupcount;
      this.bcqlowstep = item.bcqlowstep;
      this.bcqupstep = item.bcqupstep;
      this.fjmblowcount = item.fjmblowcount;
      this.fjmbupcount = item.fjmbupcount;
      this.fjmblowstep = item.fjmblowstep;
      this.fjmbupstep = item.fjmbupstep;
      this.planNo = item.planNo;
      this.centerDialogVisible = true;
      this.$http({
        url: '/caseInfo/getSF',
        method: 'POST',
        data: {
          takeNo,
          phone
        }
      })
        .then(({ data }) => {
          // console.log(data)
          if (data.code == 200) {
            // console.log(JSON.parse(data.data).reverse());
            this.activities2 = JSON.parse(data.data);
            var that = this;
            this.activities2.forEach(function (el, idx) {
              // type: 'primary',
              // color: '#f99400',
              if (idx === 0) {
                el.color = '#f99400';
              } else {
                el.color = '#f9c67c';
              }
              el.type = 'primary';
            });
            // this.activities2;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    skip() {
      window.open(window.location.href.replace('particulars', `three?caseId=${this.$store.state.caseId}`));
    },
    reminderClick(index) {
      for (let i = 0; i < this.arrflag.length; i++) {
        if (index != i) {
          this.$set(this.arrflag, i, false);
        }
      }
      this.$set(this.arrflag, index, !this.arrflag[index]);
    }
  }
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 1660px) {
  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .butonezu .el-button {
    transform: scale(0.8);
    margin: 0px;
  }

  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu .el-button {
    transform: scale(0.8);
    margin: 0px;
    width: 140px;
    float: left;
    display: block;
  }

  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu {
    width: 140px !important;
    // bottom: -36% !important;
    right: 2% !important;
  }
  // #particulars-schedule /deep/ .el-dialog {
   


  // }
  // #particulars-schedule .el-dialog .el-dialog__body .koko {
   
  // }

  // #particulars-schedule .el-dialog .el-dialog__body .wrap {
  
  // }
}

@media screen and (max-width: 1360px) {
  // #particulars-schedule /deep/ .el-dialog {
  
  
  // }
  // #particulars-schedule .el-dialog .el-dialog__body .koko {
   
  // }

  // #particulars-schedule .el-dialog .el-dialog__body .wrap {
  
  // }
}
</style>
<style scoped lang="less">
.btn-box{
	display: flex;
	  align-items: center;
	
	  
	 &>div{
		display: flex;
	  align-items: center;
	  color:#1275D2;
	  font-size: 12px;
		  margin-right: 25px;
	  cursor: pointer;
		} 
	  img{
		width: 13px;
		margin-right: 7px;
	  }
}
.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .logistics {
      width: 400px;
      height: 592px;
      background: #fff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);

      .title {
          height: 32px;
          background: #343b43;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          padding: 0 20px;
          margin-bottom: 16px;

          span:nth-of-type(2)::after {
              content: "X";
              cursor: pointer;
          }
      }

      .content {
          padding-left: 20px;
      }

      .item {
          display: flex;

          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;

          p:nth-of-type(1) {
              width: 64px;
          }

          p:nth-of-type(2) {
              width: 200px;
          }

          .icon-img {
              width: 16px;
              height: 16px;
              margin-left: 5px;
          }
      }

      .btn {
          margin-top: 12px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #1275d2;
          padding-bottom: 8px;

          border-bottom: #1275d2 solid 1px;

          &>div {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
              font-weight: normal;
              color: #1275d2;
              margin-right: 26px;

              img {
                  margin-right: 2px;
              }
          }
      }

      .list {
          height: 415px;
          overflow: auto;

          .last-box {
              &::before {
                  content: "";
                  position: absolute;
                  z-index: 99;
                  left: 0;
                  width: 10px;
                  height: 10px;
                  background: #bababa !important;
                  border-radius: 50%;
              }
          }

          &>div {
              display: flex;

              &>div:nth-of-type(1) {
                  width: 80px;

                  font-size: 12px;
                  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                  font-weight: normal;
                  color: #666666;
              }

              &>div:nth-of-type(2) {

                  width: 230px;
                  padding-bottom: 20px;
                  display: flex;
                  font-size: 14px;
                  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                  font-weight: normal;
                  color: #333333;

                  position: relative;
                  overflow: hidden;
                  padding-left: 20px;

                  // .first {}

                  .line {
                      position: absolute;
                      left: 5px;

                      border-left: 1px dashed #0089ff;

                      height: 100%;
                  }

                  &::before {
                      position: absolute;
                      left: 0;
                      content: "";
                      display: block;
                      width: 10px;
                      height: 10px;
                      background: #0089ff;
                      border-radius: 50%;
                  }
              }
          }
      }
  }
}
.planFlag {
  /deep/.el-button {
    background: #666 !important;
    border: none !important;
    span {
      color: #fff !important;
    }
  }
}
a {
  text-decoration: none;
  color: #fda21c;
  &:hover {
    color: #333;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  //针对ie
  #particulars-schedule .el-dialog .el-dialog__body .wrap .el-timeline-item__wrapper {
    top: -7px;
  }
}
#particulars-schedule {
  overflow: auto;

  .el-timeline-item {
    height: 70px;
    padding-bottom: 34px;

    .el-timeline-item__dot {
      .el-image {
        margin-left: -8px;
        width: 26px;
        height: 26px;
      }
    }

    /deep/.el-timeline-item__wrapper {
      position: relative;
      padding-left: 36px;
      font-size: 16px;
    }

    /deep/ .el-timeline-item__tail {
      border-left-style: dashed;
    }

    .line {
      height: 0;
      width: 94%;
      position: absolute;
      bottom: -34px;
      right: 0;
      border: 0.5px solid #d6d6d6;
    }

    /deep/.buttwozu {
      // height:36px;
      // width:100%;
      position: absolute;
      right: 2%;
      bottom: 0%;
      z-index: 1;

      .el-button {
        width: 140px;
        height: 36px;
        padding: 0;
        line-height: 36px;
        border: 1px solid #f99400;
        box-sizing: border-box;
        background: #f9f9f9;

        span {
          color: #fda21c;
        }

        // span:hover {
        // 	color: #fff;
        // }
      }

      // .el-button:hover {
      //   background-color: red;
      // }

      // .el-button:active {
      //   // background-color:#1175d2;
      // }
    }

    .butonezu {
      // height:36px;
      // width:100%;
      position: absolute;
      right: 2%;
      // margin-top:-15%;
      bottom: 0%;
      z-index: 1;

      /deep/.el-button {
        width: 140px;
        height: 36px;
        padding: 0;
        line-height: 36px;
        background: #f99400;
        border: none;

        span {
          color: #fff;
        }
      }
    }

    .huyi2:hover {
      background-color: #fda21c !important;
    }

    // .line:last-child{
    //     border:none;
    // }
  }

  /deep/ .el-dialog {
    width: 570px;
    height: 530px;
  
    background: #f9f9f9;
    padding-left: 50px;

    .el-dialog__header {
      padding: 25px 25px 0 40px;

      .el-dialog__title {
        font-weight: normal;
        padding: 0;
      }

      .el-dialog__headerbtn {
        top: 15px;
        right: 17px;
      }

      .el-dialog__headerbtn .el-dialog__close {
        width: 14px;
        height: 14px;
        color: #4b4b4b;
      }
    }

    .el-dialog__body {
      padding: 0;
      text-align: center;
      font-size: 16px;

      .trackingNumber {
        text-align: left;
        margin-bottom: 10px;
      }

      .koko {
        overflow: hidden;
        margin-bottom: 48px;
        p {
          text-align: left;
        }
        .p1 {
          margin-right: 60px;
        }
      }

      .wrap {
        // height: 175px;
        height: 140px;
        padding-right: 120px;
        overflow: auto;
        .el-timeline-item {
          height: auto;
        }
        .el-timeline {
          padding-top: 10px;
        }
        .el-timeline-item__wrapper {
          width: 70%;
          padding-left: 168px;
          top: -12px;
        }
        // .el-timeline-item__timestamp {
        // }
        .el-timeline-item__tail {
          height: 100%;
          border-left-style: solid;
          border-left-color: #999999;
          left: 129px;
          // top:22px;
        }
        .el-timeline-item__node--normal {
          left: 120px;
          width: 20px;
          height: 20px;
        }
        .el-timeline-item__content,
        .el-timeline-item__timestamp {
          text-align: left;
        }
        .el-timeline-item__timestamp.is-bottom {
          margin-top: 5px;
          color: #666 !important;
        }
        .time {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 16px;
        }
        .date {
          color: #333 !important;
        }
        .specificTime {
          color: #666 !important;
        }
      }
    }
  }
}
</style>

<style scoped lang="less">
@media screen and (max-width: 1660px) {
  #particulars-schedule .el-timeline-item .butonezu .el-button {
    transform: scale(0.8) !important;
    margin: 0px !important;
  }

  #particulars-schedule .el-timeline-item .buttwozu .el-button {
    transform: scale(0.8) !important;
    margin: 0px !important;
    width: 140px !important;
    float: left !important;
  }

  #particulars-schedule .el-timeline-item .buttwozu {
    width: 140px !important;
    // bottom: -36% !important;
    right: 2% !important;
  }
}

@media screen and (max-width: 1360px) {
}
</style>
<style lang="less" scoped>
.el-timeline .el-timeline-item {
  position: relative;
}
.reminder {
  position: absolute;
  top: 0;
  left: 165px;
  width: 260px;
  height: 80px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #aaa;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;
  z-index: 5;
  .top,
  .bottom {
    font-size: 14px;
    color: #333;
    i {
      font-style: normal;
    }
    span:nth-of-type(1) {
      color: #1175d2;
    }
    span:nth-of-type(2) {
      margin-right: 26px;
    }
  }
  .bottom {
    margin-top: 10px;
    span:nth-of-type(1) {
      margin-right: 13px;
    }
    span:nth-of-type(2) {
      margin-right: 34px;
    }
  }
}

.reminderStyle {
  position: absolute;
  top: 3px;
  left: 138px;
  width: 20px;
  height: 20px;
}
</style>
